.custom-pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .custom-pagination li {
    margin-right: 10px;
  }
  
  .custom-pagination a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .custom-pagination a:hover {
    background-color: #ccc;
  }
  
  .custom-pagination .active a {
    background-color: #333;
    color: #fff;
  }